<template>

	<div>
		<van-nav-bar class="navbar" title="租约变更" fixed left-arrow @click-left="leftReturn" />
		<!-- 顶部固定区域-->
		<div class="header">
			<!-- 预约切换区域-->
			<div class="choose">
				<div class="choose-one">
					<div :class="condition?'choose-one-textActive':'choose-one-text'" @click="showOnOrder">变更信息</div>
					<div class="choose-one-icon" v-show="condition"></div>
				</div>
				<div class="choose-line"></div>
				<div class="choose-one">
					<div :class="!condition?'choose-one-textActive':'choose-one-text'" @click="showFinished">物业交割单</div>
					<div class="choose-one-icon" v-show="!condition"></div>
				</div>
			</div>
		</div>
		<!-- 变更信息 -->
		<div v-if="contractType == 0">
			<!-- 最上一块住址详情 -->
			<div class="bo-dy">
				<div class="box">
					<!-- 单一列表的最上部分 （第一部分）-->
					<div class="box-head" @click="aa(userLeaseChangeDetailsList.leaseChangeStatus)">
						<!-- 左上角的状态 -->
						<div class="leftStatus3" v-if="userLeaseChangeDetailsList.leaseChangeStatus == '上级已审批' ">
							{{userLeaseChangeDetailsList.leaseChangeStatus}}
						</div>
						<div class="leftStatus1" v-if="userLeaseChangeDetailsList.leaseChangeStatus == '申请中' ">
							{{userLeaseChangeDetailsList.leaseChangeStatus}}
						</div>
						<div class="leftStatus1" v-if="userLeaseChangeDetailsList.leaseChangeStatus == '已签字' ">
							{{userLeaseChangeDetailsList.leaseChangeStatus}}
						</div>
						<div class="leftStatus1" v-if="userLeaseChangeDetailsList.leaseChangeStatus == '已提交' ">
							{{userLeaseChangeDetailsList.leaseChangeStatus}}
						</div>
						<div class="leftStatus2" v-if="userLeaseChangeDetailsList.leaseChangeStatus == '已拒绝' ">
							{{userLeaseChangeDetailsList.leaseChangeStatus}}
						</div>
						<div class="leftStatus3" v-if="userLeaseChangeDetailsList.leaseChangeStatus == '已取消' ">
							{{userLeaseChangeDetailsList.leaseChangeStatus}}
						</div>
						<div class="leftStatus4" v-if="userLeaseChangeDetailsList.leaseChangeStatus == '已完成' ">
							{{userLeaseChangeDetailsList.leaseChangeStatus}}
						</div>
						<div class="leftStatus4" v-if="userLeaseChangeDetailsList.leaseChangeStatus == '已审批' ">
							{{userLeaseChangeDetailsList.leaseChangeStatus}}
						</div>
						<!-- 右上角的状态 -->
						<div class="rightStatus2" v-if="userLeaseChangeDetailsList.leaseChangeTypeNum==0||userLeaseChangeDetailsList.leaseChangeTypeNum==1">
							{{userLeaseChangeDetailsList.leaseChangeType}}
						</div>
						<div class="rightStatus1" v-if="userLeaseChangeDetailsList.leaseChangeTypeNum==2 ||userLeaseChangeDetailsList.leaseChangeTypeNum==3">
							{{userLeaseChangeDetailsList.leaseChangeType}}
						</div>
						<div class="rightStatus3" v-if="userLeaseChangeDetailsList.leaseChangeTypeNum==4 ||userLeaseChangeDetailsList.leaseChangeTypeNum==5">
							{{userLeaseChangeDetailsList.leaseChangeType}}
						</div>
					</div>
					<!-- 列表中间的主体部分（第二部分）-->
					<div class="box-body">
						<!-- 左边的图片 -->
						<div class="leftImg" v-if="this.$route.query.roomPhoto == undefined"> <img src="../../../assets/images/default_img.jpg"
							 alt="">
						</div>
						<div class="leftImg" v-else> <img src="this.$route.query.roomPhoto" alt="">
						</div>
						<!-- 右侧文字整体 -->
						<div class="mainText">
							<!-- 右侧最上方的粗体文字 -->
							<div class="boldText">{{userLeaseChangeDetailsList.roomFuzzyAddress}}</div>
							<!-- 第二行的街道，面积，层数 -->
							<div class="oneListTop-text-detail">
								<div class="oneListTop-text-detail-info">{{userLeaseChangeDetailsList.cityProper}}</div>
								<div class="oneListTop-text-detail-line"></div>
								<div class="oneListTop-text-detail-info">{{userLeaseChangeDetailsList.builtUpArea}}㎡</div>
								<div class="oneListTop-text-detail-line"></div>
								<div class="oneListTop-text-detail-info">{{userLeaseChangeDetailsList.floor}}层</div>
							</div>
							<!-- 第三行的时间 -->
							<div class="oneListTop-text-detail-info" v-if="this.leaseType == '0' ">租期：{{userLeaseChangeDetailsList.contractBeginTime}}
								至 {{userLeaseChangeDetailsList.contractEndTime}}</div>
							<div class="oneListTop-text-detail-info" v-if="this.leaseType == '1' ">租期：{{userLeaseChangeDetailsList.ownerContractBeginTime}}
								至 {{userLeaseChangeDetailsList.ownerContractEndTime}}</div>
						</div>
					</div>
					<!-- 提交时间、处理时间（第三部分） -->
					<div class="box-time">提交时间：{{userLeaseChangeDetailsList.applicationTime}}</div>
					<span v-if="userLeaseChangeDetailsList.leaseChangeStatus=='申请中'">
						<div class="seeMore" @click="userLeaseChangeCancel" v-if="userLeaseChangeDetailsList.leaseChangeTypeNum=='2'">取消</div>
						<span v-if="userLeaseChangeDetailsList.leaseChangeTypeNum=='1'||userLeaseChangeDetailsList.leaseChangeTypeNum=='4'||userLeaseChangeDetailsList.leaseChangeTypeNum=='5'">
							<div class="seeMore" @click="userLeaseChangeCancel" v-if="userLeaseChangeDetailsList.newContractStatus=='5'||userLeaseChangeDetailsList.newContractStatus==''">取消</div>
						</span>
					</span>
				</div>
			</div>
			<!-- 中间部分退租详情 -->
			<div class="cancelDet" >
				<div class="spanbor">
					<span class="title">退租客户：</span>
					<span class="detail">{{userLeaseChangeDetailsList.userName}}</span>
				</div>
				<div class="spanbor">
					<span class="title">退租日期：</span>
					<span class="detail">{{userLeaseChangeDetailsList.estimatedDepartureTime}}</span>
				</div>
				<div class="spanbor">
					<span class="title">退租原因：</span>
					<span class="detail">{{userLeaseChangeDetailsList.remarks}}</span>
				</div>

			</div>
			<!-- 第三部分退款详情 -->
			<div class="refundtext">退款详情</div>

			<div class="cancelDet" v-if="userLeaseChangeDetailsList.contractType=='0'" style="padding-top: 10px;">
				<!-- 退款详情上半部分 -->
				<div class="spanborqq">
					<div class="titleqq">违约金：</div>
					<div class="detailrefqq" v-if="userLeaseChangeDetailsList.realFalsify != null && userLeaseChangeDetailsList.realFalsify>=0">￥{{userLeaseChangeDetailsList.realFalsify}}</div>
					<div class="detailrefqq" v-else-if="userLeaseChangeDetailsList.realFalsify != null && userLeaseChangeDetailsList.realFalsify<0">-￥{{-userLeaseChangeDetailsList.realFalsify}}</div>
					<div class="detailrefqq" v-else>无</div>
				</div>
				<div class="spanborqq">
					<div class="titleqq">剩余租金：</div>
					<div class="detailrefqq" v-if="userLeaseChangeDetailsList.remainRentMoney != null&&userLeaseChangeDetailsList.remainRentMoney>=0">￥{{userLeaseChangeDetailsList.remainRentMoney}}</div>
					<div class="detailrefqq" v-else-if="userLeaseChangeDetailsList.remainRentMoney != null&&userLeaseChangeDetailsList.remainRentMoney<0">-￥{{-userLeaseChangeDetailsList.remainRentMoney}}</div>
					<div class="detailrefqq" v-else>无</div>
				</div>
				<div class="spanborqq">
					<div class="titleqq">余留水电费：</div>
					<div class="detailrefqq" v-if="userLeaseChangeDetailsList.restWaterElectric != null&&userLeaseChangeDetailsList.restWaterElectric>=0">￥{{userLeaseChangeDetailsList.restWaterElectric}}</div>
					<div class="detailrefqq" v-else-if="userLeaseChangeDetailsList.restWaterElectric != null&&userLeaseChangeDetailsList.restWaterElectric<0">-￥{{-userLeaseChangeDetailsList.restWaterElectric}}</div>
					<div class="detailrefqq" v-else>无</div>
				</div>
				<div class="spanborqq">
					<div class="titleqq">剩余押金：</div>
					<div class="detailrefqq" v-if="userLeaseChangeDetailsList.depositMoney != null&&userLeaseChangeDetailsList.depositMoney>=0">￥{{userLeaseChangeDetailsList.depositMoney}}</div>
					<div class="detailrefqq" v-else-if="userLeaseChangeDetailsList.depositMoney != null&&userLeaseChangeDetailsList.depositMoney<0">-￥{{-userLeaseChangeDetailsList.depositMoney}}</div>
					<div class="detailrefqq" v-else>无</div>
				</div>
				<div class="spanborqq">
					<div class="titleqq">滞纳金：</div>
					<div class="detailrefqq" v-if="userLeaseChangeDetailsList.lateFee != null&&userLeaseChangeDetailsList.lateFee>=0">￥{{userLeaseChangeDetailsList.lateFee}}</div>
					<div class="detailrefqq" v-else-if="userLeaseChangeDetailsList.lateFee != null&&userLeaseChangeDetailsList.lateFee<0">-￥{{-userLeaseChangeDetailsList.lateFee}}</div>
					<div class="detailrefqq" v-else>无</div>
				</div>
				<div class="spanborqq">
					<div class="titleqq">维修赔偿：</div>
					<div class="detailrefqq" v-if="userLeaseChangeDetailsList.payRenovation != null&&userLeaseChangeDetailsList.payRenovation>=0">￥{{userLeaseChangeDetailsList.payRenovation}}</div>
					<div class="detailrefqq" v-else-if="userLeaseChangeDetailsList.payRenovation != null&&userLeaseChangeDetailsList.payRenovation<0">-￥{{-userLeaseChangeDetailsList.payRenovation}}</div>
					<div class="detailrefqq" v-else>无</div>
				</div>
				<div class="spanborqq">
					<div class="titleqq">预留水电费：</div>
					<div class="detailrefqq" v-if="userLeaseChangeDetailsList.reserveWaterElectric != null&&userLeaseChangeDetailsList.reserveWaterElectric>=0">￥{{userLeaseChangeDetailsList.reserveWaterElectric}}</div>
					<div class="detailrefqq" v-else-if="userLeaseChangeDetailsList.reserveWaterElectric != null&&userLeaseChangeDetailsList.reserveWaterElectric<0">-￥{{-userLeaseChangeDetailsList.reserveWaterElectric}}</div>
					<div class="detailrefqq" v-else>无</div>
				</div>
				<div class="spanborqq">
					<div class="titleqq">利息：</div>
					<div class="detailrefqq" v-if="userLeaseChangeDetailsList.interest != null&&userLeaseChangeDetailsList.interest>=0">￥{{userLeaseChangeDetailsList.interest}}</div>
					<div class="detailrefqq" v-else-if="userLeaseChangeDetailsList.interest != null&&userLeaseChangeDetailsList.interest<0">-￥{{-userLeaseChangeDetailsList.interest}}</div>
					<div class="detailrefqq" v-else>无</div>
				</div>
				<div class="spanborqq">
					<div class="titleqq">租客是否补款：</div>
					<div class="detailrefqq" v-if="userLeaseChangeDetailsList.isUserPayed != null&&userLeaseChangeDetailsList.isUserPayed>=0">￥{{userLeaseChangeDetailsList.isUserPayed}}</div>
					<div class="detailrefqq" v-else-if="userLeaseChangeDetailsList.isUserPayed != null&&userLeaseChangeDetailsList.isUserPayed<0">-￥{{-userLeaseChangeDetailsList.isUserPayed}}</div>
					<div class="detailrefqq" v-else>无</div>
				</div>
				<div class="spanborqq">
					<div class="titleqq">补款金额：</div>
					<div class="detailrefqq" v-if="userLeaseChangeDetailsList.UserPayedMoney != null&&userLeaseChangeDetailsList.UserPayedMoney>=0">￥{{userLeaseChangeDetailsList.UserPayedMoney}}</div>
					<div class="detailrefqq" v-else-if="userLeaseChangeDetailsList.UserPayedMoney != null&&userLeaseChangeDetailsList.UserPayedMoney<0">-￥{{-userLeaseChangeDetailsList.UserPayedMoney}}</div>
					<div class="detailrefqq" v-else>无</div>
				</div>
				<!-- 退款详情下半部分 -->
				<div class="remaining">
					<span class="title">剩余退还</span>
					<span class="detailrem" v-if="userLeaseChangeDetailsList.userTotalPay != null&&userLeaseChangeDetailsList.userTotalPay>=0">￥{{userLeaseChangeDetailsList.userTotalPay}}</span>
					<span class="detailrem" v-else-if="userLeaseChangeDetailsList.userTotalPay != null&&userLeaseChangeDetailsList.userTotalPay<0">-￥{{-userLeaseChangeDetailsList.userTotalPay}}</span>
					<span class="detailrem" v-else>无</span>
				</div>
			</div>



			<div class="cancelDet" v-if="userLeaseChangeDetailsList.contractType=='1'" style="padding-top: 10px;">
				<!-- 退款详情上半部分 -->
				<div class="spanborqq">
					<div class="titleqq">业主退回房租：</div>
					<div class="detailrefqq" v-if="userLeaseChangeDetailsList.remainRentMoney != null&&userLeaseChangeDetailsList.remainRentMoney>=0">￥{{userLeaseChangeDetailsList.remainRentMoney}}</div>
					<div class="detailrefqq" v-else-if="userLeaseChangeDetailsList.remainRentMoney != null&&userLeaseChangeDetailsList.remainRentMoney<0">-￥{{-userLeaseChangeDetailsList.remainRentMoney}}</div>
					<div class="detailrefqq" v-else>无</div>
				</div>
				<div class="spanborqq">
					<div class="titleqq">支付业主房租：</div>
					<div class="detailrefqq" v-if="userLeaseChangeDetailsList.surplusDepositMoney != null&&userLeaseChangeDetailsList.surplusDepositMoney>=0">￥{{userLeaseChangeDetailsList.surplusDepositMoney}}</div>
					<div class="detailrefqq" v-else-if="userLeaseChangeDetailsList.surplusDepositMoney != null&&userLeaseChangeDetailsList.surplusDepositMoney<0">-￥{{-userLeaseChangeDetailsList.surplusDepositMoney}}</div>
					<div class="detailrefqq" v-else>无</div>
				</div>
				<div class="spanborqq">
					<div class="titleqq">违约金：</div>
					<div class="detailrefqq" v-if="userLeaseChangeDetailsList.realFalsify != null&&userLeaseChangeDetailsList.realFalsify>=0">￥{{userLeaseChangeDetailsList.realFalsify}}</div>
					<div class="detailrefqq" v-else-if="userLeaseChangeDetailsList.realFalsify != null&&userLeaseChangeDetailsList.realFalsify<0">-￥{{-userLeaseChangeDetailsList.realFalsify}}</div>
					<div class="detailrefqq" v-else>无</div>
				</div>
				<div class="spanborqq">
					<div class="titleqq">装修赔偿：</div>
					<div class="detailrefqq" v-if="userLeaseChangeDetailsList.payRepair != null&&userLeaseChangeDetailsList.payRepair>=0">￥{{userLeaseChangeDetailsList.payRepair}}</div>
					<div class="detailrefqq" v-else-if="userLeaseChangeDetailsList.payRepair != null&&userLeaseChangeDetailsList.payRepair<0">-￥{{-userLeaseChangeDetailsList.payRepair}}</div>
					<div class="detailrefqq" v-else>无</div>
				</div>
				<div class="spanborqq">
					<div class="titleqq">公司退回总额：</div>
					<div class="detailrefqq" v-if="userLeaseChangeDetailsList.userTotalPay != null&&userLeaseChangeDetailsList.userTotalPay>=0">￥{{userLeaseChangeDetailsList.userTotalPay}}</div>
					<div class="detailrefqq" v-else-if="userLeaseChangeDetailsList.userTotalPay != null&&userLeaseChangeDetailsList.userTotalPay<0">-￥{{-userLeaseChangeDetailsList.userTotalPay}}</div>
					<div class="detailrefqq" v-else>无</div>
				</div>
				<!-- 退款详情下半部分 -->
				<div class="remaining">
					<span class="title">业主退回总额</span>
					<span class="detailrem" v-if="userLeaseChangeDetailsList.realBreakMoney != null&&userLeaseChangeDetailsList.realBreakMoney>=0">￥{{userLeaseChangeDetailsList.realBreakMoney}}</span>
					<span class="detailrem" v-else-if="userLeaseChangeDetailsList.realBreakMoney != null&&userLeaseChangeDetailsList.realBreakMoney<0">-￥{{-userLeaseChangeDetailsList.realBreakMoney}}</span>
					<span class="detailrem" v-else>无</span>
				</div>
			</div>



		</div>
		<div style="width: 100%;height: 70px;"></div>
		<!-- 物业交割 -->
		<div v-if="contractType == 1">
			<common-empty v-if="swi == '0'" style="margin-top: 50px;"></common-empty>
			<div v-if="contractType == 1 && swi == '1'">

				<div class="container" v-if="tenantContractPropertyFale == true">
					<!-- 物业交割单 -->
					<div class="body">
						<div class="icon">
							<img src="../../../assets/images/life-pay.png">
						</div>
						<div class="lifePay">生活缴费</div>
					</div>
					<!-- 电表 -->
					<div v-for=" (item,i) in tenantContractProperty" :key="i">
						<div class="payInfo">
							<div class="payInfo-image" v-if="item.wattMeterPath != '' ">
								<img :src="item.wattMeterPath" @click="previewPropertyDetailPhotos(2,0,item.wattMeterPath)">
							</div>

							<div class="payInfo-image" v-if="item.wattMeterPath == ''  ">
								<img src="../../../assets/images/electricIcon.png" alt="">
							</div>

							<div class="payInfo-right">
								<div class="payInfo-name">电表</div>
								<div class="payInfo-number">{{item.wattMeterCodeTypeStr}}：{{item.wattMeterCode}}</div>
								<div class="payInfo-account">底表数：{{item.wattMeterNumber}}</div>
                <div class="payInfo-remark">备注：{{item.wattMeterRemarks}}</div>
							</div>
						</div>
						<!-- 水表 -->
						<div class="payInfo">
							<div class="payInfo-image" v-if="item.waterPath != ''  ">
								<img :src="item.waterPath" @click="previewPropertyDetailPhotos(2,0,item.waterPath)">
							</div>

							<div class="payInfo-image" v-if="item.waterPath == ''  ">
								<img src="../../../assets/images/waterIcon.png" alt="">
							</div>

							<div class="payInfo-right">
								<div class="payInfo-name">水表</div>
								<div class="payInfo-number">{{item.waterMeterCodeTypeStr}}：{{item.waterMeterCode}}</div>
								<div class="payInfo-account">底表数：{{item.waterMeterNumber}}</div>
                <div class="payInfo-remark">备注：{{item.waterMeterRemarks}}</div>
              </div>
						</div>
						<!-- 燃气表 -->
						<div class="payInfo">
							<div class="payInfo-image" v-if="item.gasMeterPath != ''  ">
								<img :src="item.gasMeterPath" @click="previewPropertyDetailPhotos(2,0,item.gasMeterPath)">
							</div>
							<div class="payInfo-image" v-if="item.gasMeterPath == ''  ">
								<img src="../../../assets/images/fireIcon.png" alt="">
							</div>
							<div class="payInfo-right">
								<div class="payInfo-name">燃气表</div>
								<div class="payInfo-number">{{item.gasMeterCodeTypeStr}}：{{item.gasMeterCode}}</div>
								<div class="payInfo-account">底表数：{{item.gasMeterNumber}}</div>
                <div class="payInfo-remark">备注：{{item.gasMeterRemarks}}</div>

              </div>

						</div>
						<div class="payInfoB">
							<span style="">交割备注：</span><span class="payInfoText" style="">{{item.description}}</span>
						</div>
            <!-- 检查项-->
            <div class="middle" v-if="leaseType==0&&roomPricingTagDetail!=0">
              <div class="icon">
                <img src="../../../assets/images/furniture-icon.png">
              </div>
              <div class="lifeHome">房间检查项</div>
            </div>
            <div class="finuture" v-if="leaseType==0&&roomPricingTagDetail!=0">
              <div class="goodsInfo clearfix">
                <div v-for="(ite,cidx) in roomPricingTagDetail" :key="cidx">
                  <div class="goods-name">{{ite.pricingTagName}}</div>
                  <div class="goods-image" @click="showPropertyDetailPhotos(ite)">查看实物图></div>
                  <div class="goods-status"><span>存在：{{ite.itemCount==1?'有':'无'}}</span><span>状态：{{ite.dictionaryTitle}}</span></div>
                  <div class="goods-describe">描述：{{ite.itemDescription}}</div>
                </div>
                <!--配置物品弹出层-->
                <van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
                  <div class="swiper_show" ref="wrapperBox">
                    <div class="content">
                      <div class="imgWrap" v-for="(url,i) in propertyDetailPhotos" :key="i" @click="previewPropertyDetailPhotos(1,i,'')">
                        <van-image width="100" height="75" fit="contain" radius="10" :src="url" />
                      </div>
                    </div>
                    <div class="num">{{propertyDetailPhotos.length}}图</div>
                  </div>
                </van-popup>
              </div>
            </div>

            <div class="middle" v-if="leaseType==0&&houseType=='1'&&publicPricingTagDetail.length!=0">
              <div class="icon">
                <img src="../../../assets/images/furniture-icon.png">
              </div>
              <div class="lifeHome">公区检查项</div>
            </div>
            <div class="finuture" v-if="leaseType==0&&houseType=='1'&&publicPricingTagDetail.length!=0">
              <div class="goodsInfo clearfix">
                <div v-for="(ite,cidx) in publicPricingTagDetail" :key="cidx">
                  <div class="goods-name">{{ite.pricingTagName}}</div>
                  <div class="goods-image" @click="showPropertyDetailPhotos(ite)">查看实物图></div>
                  <div class="goods-status"><span>存在：{{ite.itemCount==1?'有':'无'}}</span><span>状态：{{ite.dictionaryTitle}}</span></div>
                  <div class="goods-describe">描述：{{ite.itemDescription}}</div>
                </div>
                <!--配置物品弹出层-->
                <van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
                  <div class="swiper_show" ref="wrapperBox">
                    <div class="content">
                      <div class="imgWrap" v-for="(url,i) in propertyDetailPhotos" :key="i" @click="previewPropertyDetailPhotos(1,i,'')">
                        <van-image width="100" height="75" fit="contain" radius="10" :src="url" />
                      </div>
                    </div>
                    <div class="num">{{propertyDetailPhotos.length}}图</div>
                  </div>
                </van-popup>
              </div>
            </div>

						<div v-for=" (item,i) in tenantPropertyDetail " :key="i">
							<div class="middle">
								<div class="icon">
									<img src="../../../assets/images/furniture-icon.png">
								</div>
								<div class="lifeHome">{{item.name}}</div>
							</div>
							<div class="finuture">
								<div class="goodsInfo clearfix">
									<div v-for="(ite,cidx) in item.sublist" :key="cidx">
										<div class="goods-name">{{ite.itemName}}</div>
										<div class="goods-image" @click="showPropertyDetailPhotos(ite)">查看实物图></div>
										<div class="goods-describe">描述：{{ite.itemDescription}}</div>
									</div>
									<!--配置物品弹出层-->
									<van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
										<div class="swiper_show" ref="wrapperBox">
											<div class="content">
												<div class="imgWrap" v-for="(url,i) in propertyDetailPhotos" :key="i" @click="previewPropertyDetailPhotos(1,i,'')">
													<van-image width="100" height="75" fit="contain" radius="10" :src="url" />
												</div>
											</div>
											<div class="num">{{propertyDetailPhotos.length}}图</div>
										</div>
									</van-popup>
								</div>
							</div>

						</div>
						<div class="foot">
						</div>
						<!-- 待确认 -->
						<div v-if="item.isUserComfirm!=1">
							<div class="bottom">
								<div class="bottom-left">{{leaseType==0?'待租客确认':'待业主确认'}}</div>
								<div class="bottom-right" @click="confirm(item.id)">确认</div>
							</div>
						</div>
						<!-- 已确认 -->
						<div v-if="item.isUserComfirm==1">
							<div class="confirm">
								<div class="confirm-left">已确认，确认人：{{item.userName}}</div>
								<div class="confirm-right">{{item.conformTime}}</div>
							</div>
						</div>
					</div>
				</div>

			</div>

		</div>
	</div>

</template>

<script>
	import {
		NavBar,
		Picker,
		List,
		PullRefresh,
		Toast,
		Popup,
		Image as VanImage,
		Uploader,
		ImagePreview,
		popup
	} from "vant";
	Vue.use(Picker);
	Vue.use(List);
	Vue.use(PullRefresh);
	Vue.use(Popup);
	Vue.use(PullRefresh);
	import {
		userLeaseChangeDetails,
		userLeaseChangeCancel,
		queryContractProperty,
		confirmContractProperty
	} from "../../../getData/getData";
	import {
		getUserId,
		globaluserId,
		responseUtil
	} from "../../../libs/rongxunUtil";
	import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
	import Vue from 'vue';
	import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'

	export default {
		name: "changeDetails",
		data() {
			return {
				show: false,
				showPicker: false,
				condition: true, //用来控制两种状态的切换条件
				isOccupationShow: false,
				loading: false,
				finished: false,
				count: 0,
				certificateType: '',
				isOccupationShow: false,
				defaultIndex: '',
				// swi: true,
				isLoading: false,
				totalPage: '',
				userLeaseChangeDetailsList: '', //整体信息的列表
				leaseType: '', //前一个页面传来的合同类型
        houseType:'',
				contractType: '0', //切换
				//展示配置物品图片信息
				propertyDetailPhotos: [],
				contractProperty: [],
				contractPropertyDetail: [],
				tenantContractProperty: [],
				tenantPropertyDetail: [],
        roomPricingTagDetail:[],
        publicPricingTagDetail:[],
				contractId: this.$route.params.contractId,
				id: '',
				tenantContractPropertyFale: true,
				swi: '0',
			}
		},
		components: {
			[NavBar.name]: NavBar,
			[List.name]: List,
			[Uploader.name]: Uploader,
			[popup.name]: popup,
			[VanImage.name]: VanImage,
			[Toast.name]: Toast,
			[PullRefresh.name]: PullRefresh,
			dialMobile,
			rxNavBar

		},

		created() {
			this.initData();
			this.userLeaseChangeDetails();
			this.leaseType = this.$route.query.leaseType
      this.houseType = this.$route.query.houseType
			console.log(this.leaseType)
		},
		//初始化
		mounted() {
			this.InitData()
		},
		methods: {
			//初始化数据
			initData() {
				const that = this
				let initData = {
					user_id: globaluserId()
				}
			},
			aa(ee) {
				console.log(ee)
			},
			//租约变更详情初始化接口
			userLeaseChangeDetails: function() {
				const that = this
				let initData = {}
				initData.user_id = globaluserId()
				initData.userLeaseChange_id = this.$route.query.userLeaseChange_id
				userLeaseChangeDetails(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {

						that.userLeaseChangeDetailsList = response.data.data.userLeaseChangeDetails
						console.log(that.userLeaseChangeDetailsList)
					})
				})
			},
			//租约变更取消接口
			userLeaseChangeCancel: function() {
				const that = this
				let initData = {}
				initData.user_id = globaluserId()
				initData.userLeaseChange_id = this.$route.query.userLeaseChange_id
				userLeaseChangeCancel(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						Toast('取消成功')
						that.leftReturn()
					})
				})
			},
			showPopup() {
				this.show = true;
			},
			//租客租约
			showOnOrder() {
				var that = this
				that.condition = true
				this.contractType = "0"
				this.default();
				this.onLoad();
			},
			//业主租约
			showFinished() {
				var that = this
				that.condition = false
				this.contractType = "1"
				this.default();
				this.onLoad();
			},
			//返回上一页
			leftReturn() {
				this.$router.go(-1)
			},
			//分页
			onLoad() {},
			default () { //默认值
				this.pageCount = ''
				this.currentPage = '1'
			},

			//物业交割单初始化数据
			InitData() {
				let that = this
				let initData = {};
				initData.contract_id = that.$route.query.contractId //合同id
				// initData.contract_id = 88; //合同id
        if(that.$route.query.contractPropertyId){
          initData.contractPropertyId = that.$route.query.contractPropertyId
        }
				queryContractProperty(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						if (response.data.data.tenantContractProperty) {
							if (response.data.data.tenantContractProperty.length > 0) {
								that.swi = '1'
							}
						}
						if (response.data.data) {
							that.tenantContractProperty = response.data.data.tenantContractProperty //水电表信息
							that.tenantPropertyDetail = response.data.data.tenantPropertyDetail //物品详情信息
              that.roomPricingTagDetail = response.data.data.roomPricingTagDetail  //一套表的检查项
              that.publicPricingTagDetail = response.data.data.publicPricingTagDetail  //一套表的检查项

            }
						//     console.log(response.data.data)

					})
				})

			},
			//点击配置物品详情按钮
			showPropertyDetailPhotos(ite) {
				//this.propertyDetailPhotos
				if (ite.photoList == undefined) {
					Toast('暂无实物图')
				} else {
					this.propertyDetailPhotos = []
					let list = ite.photoList
					for (let i = 0; i < list.length; i++) {
						this.propertyDetailPhotos.push(list[i].path)
					}
					this.show = true
				}
			},
			previewPropertyDetailPhotos(type,start,path) {
				var images = []
				if(type == 1){
					images = this.propertyDetailPhotos
				}else{
					images.push(path)
				}
				ImagePreview({
					images: images,
					startPosition: start,
				});
			},
			//确认  tenantContractProperty的isUserComfirm不是1就显示
			confirm(e) {
				this.id = e
				this.getConfirmContractProperty();
			},
			/**
			 * 物业交割确认接口
			 */
			getConfirmContractProperty() {
				let that = this
				let initData = {};
				initData.id = that.id; //物业交割单id
				initData.user_id = globaluserId(); //用户id
				confirmContractProperty(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						that.InitData()
					})
				})
			},
		},

	}
</script>

<style lang="less" scoped>
	.navbar {
		background-color: white;
		border-bottom: 2px solid white;
	}

	.header {
		width: 100%;
		height: auto;
		position: fixed;
		z-index: 8;
		top: 52px;
		background-color: #f8f8f8;
	}

	.choose {
		width: 100%;
		height: auto;
		background-color: white;
		display: flex;
	}

	.choose-one {
		width: 187px;
		text-align: center;
	}

	.choose-one-textActive {
		width: 70px;
		height: 20px;
		line-height: 20px;
		font-size: 14px;
		font-weight: bold;
		margin-top: 24px;
		margin-left: 58px;
		color: rgba(255, 93, 59, 1);
		text-align: center;
		font-family: PingFangSC-Semibold;
	}

	.choose-one-text {
		width: 70px;
		height: 20px;
		line-height: 20px;
		font-size: 14px;
		margin-top: 24px;
		margin-left: 58px;
		color: rgba(153, 153, 153, 1);
		text-align: center;
		font-family: PingFangSC-Semibold;
	}

	.choose-one-icon {
		width: 20px;
		height: 4px;
		border-radius: 3px;
		background-color: rgba(255, 93, 59, 1);
		margin: 5px 0 15px 83px;
	}

	.choose-line {
		width: 2px;
		height: 17px;
		margin-top: 23px;
		background-color: rgba(239, 239, 239, 1);
	}

	.type {
		top: 110px;
		padding-left: 20px;
		color: rgba(255, 93, 59, 1);
		font-size: 12px;
		position: fixed;
		z-index: 666;
		display: block;
		width: 100%;
		background-color: rgb(248, 248, 248);
		/* background-color: red; */
		height: 45px;
		line-height: 45px;
	}

	::v-deep .van-dropdown-menu__bar {
		background: #f6f6f6;
		float: left;
	}

	.bo-dy {
		margin-top: 154px;
	}

	.box {
		width: 345px;
		background-color: white;
		border-radius: 8px;
		margin: 0 auto;
		margin-bottom: 17px;
	}

	.box-head {
		height: 37px;
		border-bottom: 1px solid rgba(239, 239, 239, 1);
	}

	.leftStatus1,
	.leftStatus2,
	.leftStatus3,
	.leftStatus4 {
		padding-left: 5px;
		padding-right: 5px;
		height: 16px;
		line-height: 16px;
		;
		color: rgba(255, 255, 255, 1);
		font-size: 10px;
		text-align: center;
		float: left;
		border-top-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	.leftStatus1 {
		background-image: linear-gradient(to right, #66CB94, #28D3B0);
	}

	.leftStatus2 {
		background-image: linear-gradient(to right, #E02020, #FF431B);
	}

	.leftStatus3,
	.leftStatus4 {
		background-image: linear-gradient(to right, #999999, #999999);
	}

	.rightStatus1,
	.rightStatus2,
	.rightStatus3 {
		float: right;
		font-size: 12px;
		font-weight: 600;
		margin-right: 15px;
		margin-top: 9px;
	}

	.rightStatus1 {
		color: #E02020;
	}

	.rightStatus2 {
		color: #3191FF;
	}

	.rightStatus3 {
		color: #FF5D3B;
	}

	.box-body {
		height: 104px;
		border-bottom: 1px solid rgba(239, 239, 239, 1);
	}

	.leftImg img {
		margin-left: 10px;
		margin-top: 15px;
		width: 100px;
		height: 75px;
		border-radius: 8px;
		float: left;
	}

	.mainText {
		float: left;
		width: 210px;
		height: 70px;
		margin-left: 8px;
		margin-top: 16px;
	}

	.boldText {
		font-size: 15px;
		font-weight: 700;
		width: 220px;
		white-space: nowrap;
		/*内容超宽后禁止换行显示*/
		overflow: hidden;
		/*超出部分隐藏*/
		text-overflow: ellipsis;
		/*文字超出部分以省略号显示*/
	}

	.oneListTop-text-detail {
		height: 17px;
		margin-top: 5px;
		color: rgba(102, 102, 102, 1);
		font-size: 12px;
		text-align: left;
		font-family: PingFangSC-Regular;
		display: flex;
		align-items: center;
		margin-bottom: 5px;
	}

	.oneListTop-text-detail-line {
		width: 1px;
		height: 12px;
		background-color: rgba(102, 102, 102, 1);
		margin: 0 10px;
	}

	.oneListTop-text-detail-info {
		color: #666666;
		font-size: 12px;
		font-weight: 500;
	}

	.box-time {
		color: rgba(153, 153, 153, 1);
		font-size: 12px;
		padding-left: 10px;
		height: 55px;
		line-height: 55px;
	}

	.seeMore {
		width: 80px;
		height: 30px;
		border-radius: 8px;
		border: 1px solid transparent;
		border-radius: 8px;
		/* 圆角属性测试 */
		background-image: linear-gradient(white, white),
			/* 底色，即原有的背景 */
			linear-gradient(to right, #FFC884, #FF9668, #FF7857);
		/* 模拟渐变边框 */
		background-clip: padding-box, border-box;
		background-origin: border-box;
		line-height: 30px;
		margin-right: 12px;
		color: rgba(255, 93, 59, 1);
		font-size: 12px;
		text-align: center;
		font-family: PingFangSC-Regular;
		float: right;
		margin-top: -42px;
	}

	.cancelDet {
		height: auto;
		width: 345px;
		border-radius: 8px;
		background-color: rgba(255, 255, 255, 1);
		margin: 0 auto;
		padding-top: 5px;
		padding-bottom: 10px;
	}

	.spanbor {
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.title {
		margin-left: 15px;
		font-size: 14px;
		font-family: PingFangSC-Semibold;
		font-weight: 600;
	}

	.detail,
	.detailref,
	.detailrem {
		font-size: 12px;
		color: rgba(153, 153, 153, 1);
	}

	.refundtext {
		color: #FF5D3B;
		font-size: 12px;
		font-family: PingFangSC-Semibold;
		font-weight: 600;
		margin-top: 30px;
		padding-left: 15px;
		margin-bottom: 10px;
	}

	.detailref,
	.detailrem {
		font-family: PingFangSC-Semibold;
		float: right;
		margin-right: 15px;
		margin-top: 12px;
	}

	.remaining {
		height: 45px;
		line-height: 45px;
		border-top: 1px solid #EFEFEF;
	}

	.detailrem {
		color: #FF5D3B;
		font-weight: 600;
		margin-top: 5px;
	}

	/* 物业交割单css */
	.container {
		width: 100%;
		height: 100%;
		background-color: #fafafa;
		overflow: hidden;
		margin-top: 40px;
	}

	.body {
		width: 100%;
		height: 25px;
		margin-top: 31.5px;
		margin-bottom: 4px;
		display: flex;
	}

	.body .icon {
		width: 25px;
		height: 25px;
		margin-left: 22px;
	}

	.body .icon image {
		width: 100%;
		height: 100%;
	}

	.body .lifePay {
		width: 75px;
		height: 17px;
		margin-top: 6px;
		color: rgba(34, 34, 34, 1);
		font-size: 12px;
		font-weight: 700;
		font-family: PingFangSC-Semibold;
	}

	.payInfo {
    display: flex;
    align-items: center;
    justify-content: space-around;
		width: 332px;
		//height: 95px;
		margin-left: 22px;
		margin-bottom: 15px;
		border-radius: 8px;
		background-color: rgba(255, 255, 255, 1);
	}

	.payInfo .payInfo-image {
		width: 100px;
		height: 75px;
		//margin-top: 10px;
		//margin-left: 14px;
		float: left;
		border-radius: 8px;
		background-color: rgba(245, 245, 245, 1);
	}

	.payInfo .payInfo-image img {
		width: 100%;
		height: 100%;
	}

	.payInfo .payInfo-right {
		width: 50%;
		//height: 100%;
		//float: left;
		//margin-left: 8px;
		//overflow: hidden;
	}

	.payInfo .payInfo-right .payInfo-name {
		//width: 100%;
		//height: 21px;
		margin-top: 15px;
		color: rgba(34, 34, 34, 1);
		font-size: 15px;
		//line-height: 21px;
		font-weight: 700;
		text-align: left;
		font-family: PingFangSC-Semibold;
	}

	.payInfo .payInfo-right .payInfo-number {
		//width: 100%;
		//height: 17px;
		margin-top: 8px;
		//line-height: 17px;
		color: rgba(102, 102, 102, 1);
		font-size: 12px;
		text-align: left;
		font-family: PingFangSC-Regular;
	}

	.payInfo .payInfo-right .payInfo-account {
		//width: 100%;
		//height: 17px;
		margin-top: 2px;
		//line-height: 17px;
		color: rgba(102, 102, 102, 1);
		font-size: 12px;
		text-align: left;
		font-family: PingFangSC-Regular;
	}

  .payInfo .payInfo-right .payInfo-remark {
    //width: 100%;
    //height: 17px;
    margin-top: 2px;
    margin-bottom: 15px;
    //line-height: 17px;
    text-align: left;

    color: rgba(102, 102, 102, 1);
    font-size: 12px;
    font-family: PingFangSC-Regular;
  }

	.middle {
		width: 100%;
		height: 40px;
		overflow: hidden;
	}

	.middle .icon {
		width: 25px;
		height: 25px;
		float: left;
		margin-left: 20px;
		margin-top: 11px;
	}

	.middle .icon image {
		width: 100%;
		height: 100%;
	}

	.middle .lifeHome {
		float: left;
		width: 86px;
		height: 17px;
		margin-top: 14px;
		color: rgba(34, 34, 34, 1);
		font-size: 12px;
		font-weight: 700;
		line-height: 17px;
		font-family: PingFangSC-Semibold;
	}

	.clearfix::before,
	.clearfix::after {
		content: '';
		display: table;
		clear: both;
	}

	.goodsInfo {
		width: 332px;
		height: auto;
		margin-left: 22px;
		margin-bottom: 14px;
		border-radius: 16px;
		background-color: rgba(255, 255, 255, 1);
	}

	.goodsInfo .goods-name {
		min-width: 40px;
		height: 20px;
		margin-top: 12px;
		margin-left: 22px;
		float: left;
		color: rgba(34, 34, 34, 1);
		line-height: 20px;
		font-weight: 700;
		font-size: 14px;
		text-align: left;
		font-family: PingFangSC-Semibold;
	}

	.goodsInfo .goods-image {
		width: 68px;
		height: 17px;
		margin-top: 15px;
		margin-right: 20px;
		float: right;
		line-height: 17px;
		color: rgba(49, 145, 255, 1);
		font-size: 12px;
		text-align: left;
		font-family: PingFangSC-Regular;
		white-space: nowrap;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;
	}
  .goodsInfo .goods-status {
    width: 290px;
    height: auto;
    //margin-top: 5px;
    //margin-bottom: 15px;
    margin-left: 22px;
    float: left;
    line-height: 17px;
    color: rgba(153, 153, 153, 1);
    font-size: 12px;
    text-align: left;
    font-family: PingFangSC-Regular;
    >span{
      margin-right: 35px;
    }
  }

	.goodsInfo .goods-describe {
		width: 290px;
		height: auto;
		margin-top: 5px;
		margin-bottom: 15px;
		margin-left: 22px;
		float: left;
		line-height: 17px;
		color: rgba(153, 153, 153, 1);
		font-size: 12px;
		text-align: left;
		font-family: PingFangSC-Regular;
	}



	.finuture {
		width: 100%;
		height: auto;

	}

	.foot {
		width: 100%;
		height: 100px;
	}


	.bottom {
		width: 100%;
		height: 65px;
		position: fixed;
		bottom: 0px;
		background-color: rgba(250, 250, 250, 1);
		box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.07);
	}

	.bottom .bottom-left {
		float: left;
		width: 60px;
		height: 17px;
		line-height: 17px;
		margin-top: 24px;
		margin-left: 20px;
		color: rgba(255, 93, 59, 1);
		font-size: 12px;
		text-align: left;
		font-family: PingFangSC-Regular;
	}

	.bottom .bottom-right {
		float: right;
		width: 91px;
		height: 40px;
		line-height: 40px;
		margin-top: 12px;
		margin-right: 13px;
		color: rgba(255, 255, 255, 1);
		background: linear-gradient(to right, #ffc274, #ff5d3b);
		border-radius: 8px;
		box-shadow: 0px 1px 1px 0px rgba(188, 0, 0, 0.2);
		font-size: 14px;
		text-align: center;
		font-family: PingFangSC-Semibold;
	}

	.confirm {
		width: 100%;
		position: fixed;
		z-index: 999;
		bottom: 0px;
		height: 65px;
		background-color: rgba(250, 250, 250, 1);
		box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.07);
	}

	.confirm .confirm-left {
		float: left;
		width: 180px;
		height: 20px;
		font-weight: 700;
		line-height: 20px;
		margin-top: 22px;
		margin-left: 20px;
		color: rgba(255, 93, 59, 1);
		font-size: 14px;
		text-align: left;
		font-family: PingFangSC-Regular;
	}

	.confirm .confirm-right {
		float: right;
		min-width: 85px;
		height: 20px;
		line-height: 20px;
		margin-top: 22px;
		margin-right: 20px;
		color: rgba(153, 153, 153, 1);
		font-size: 14px;
		text-align: left;
		font-family: PingFangSC-Semibold
	}

	/* 图片墙 */
	.swiper_show {
		width: 325px;
		position: relative;
		margin: 15px 10px;
		height: 75px;

		.content {
			position: absolute;
			display: flex;
			justify-content: space-around;
			left: 0px;

			.imgWrap {
				margin-right: 10px;
			}
		}

		.num {
			position: absolute;
			left: 4px;
			top: 6px;
			font-size: 10px;
			background-color: rgb(0, 0, 0);
			opacity: 0.5;
			color: rgb(255, 255, 255);
			padding: 2px 6px;
			display: inline-block;
			border-radius: 10px;
			font-weight: bolder;
			line-height: 10px;
		}
	}

	.spanborqq {
		height: 20px;
		margin-top: 2px;
		margin-bottom: 5px;
		margin-left: 15px;
		margin-right: 15px;
	}

	.titleqq {
		float: left;
		font-size: 14px;
		font-family: PingFangSC-Semibold;
		font-weight: 600;
		// margin-bottom: 5px;
	}

	.detailrefqq {
		float: right;
		font-size: 12px;
		color: rgba(153, 153, 153, 1);
	}

	.payInfoB {
		padding-top: 5px;
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 5px;
		min-height: 25px;
		background-color: #ffffff;
		// margin: 0 20px;
		width: 312px;
		margin: 0 auto;
		border-radius: 8px;
		font-size: 13px;
		padding: 10px 10rpx;
		color: black;
		font-size: 15px;
		// line-height: 21px;
		font-weight: 700;
		text-align: left;
		font-family: PingFangSC-Semibold;
	}

	.payInfoText {
		font-weight: 500;
		font-size: small;
		color: #666666;
	}
</style>
